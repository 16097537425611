// COLORS
$primary-color: #19283a;
$secondary-color: rgba(25, 40, 58, 0.9);
$tertiary-color: rgba(25, 40, 58, 0.4);

$white-color: #d7dde4;
$dark-grey: #000;
$light-grey: #d2d2d2;
$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
